import React from 'react';

import * as styles from '../styles/pages/about-npc.module.css';
import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Title from '../components/Title/Title';
import Columns from '../components/Columns/Columns';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';

import heroAboutImg from '../images/aboutNpc/hero.png';
import headImg from '../images/aboutNpc/head.png';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>What is nasopharyngeal cancer (NPC)? | Loqtorzi™ Patient website</title>
      <meta name='description' content='Discover what nasopharyngeal cancer is and the different types you can have. Please see Indication and Important Safety Information'/>
    </>
  )
}

export default function AboutNPC() {
  return (
    <Layout>
      <Hero image={ { src: heroAboutImg, alt:'Hero about NPC' } }>
        <Title>
          <h1>What is NPC?</h1>
        </Title>
        <div className={'mt-[1.8rem] tablet:mt-[2.9rem]'}>
        NPC is a type of head and neck cancer located in the nasopharynx, which starts in the upper part of the throat behind the nose and near the base of the skull.
        <br/>&nbsp;
        </div>
      </Hero>
      <GlobalWrapper>
        <Columns className={ styles.boxWithHead } colClassName={ styles.cols }>
          <div className={ styles.col1 }>
            <div className={ styles.image }>
              <img src={ headImg } alt={ 'Man head' } />
            </div>
          </div>
          <div className={ styles.col2 }>
            <div>
              <h2>Types of NPC:</h2>
              <br/>
              <div><strong className='inline'>Recurrent</strong>=when NPC returns to the original tumor location.</div>
              <div><strong className='inline'>Locally advanced</strong>=when NPC spreads to nearby tissues.</div>
              <div><strong className='inline'>Metastatic=</strong>when NPC spreads to other parts of the body.</div>
            </div>
          </div>
        </Columns>
      </GlobalWrapper>
      <GlobalWrapper className={'desktop:pb-[8rem]'}>
        <ColumnsConnections className={'mt-[3.6rem] desktop:mt-[8.8rem]'}/>
      </GlobalWrapper>
    </Layout>
  );
}
